import React from 'react';

const GoogleMap = () => {
  return (
    <div style={{ width: '100%', height: '400px', maxWidth: '100%' }}>
      <iframe
        title="Google Maps"
        width="100%"
        height="100%"
        frameBorder="0"
        style={{ border: '0' }}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1543328.7900472784!2d-111.1695125!3d40.930784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x87528aa06bd6f571%3A0x83c25f12a892f1b2!2sBluMont%20Building%20Services!5e0!3m2!1sen!2sin!4v1708941461687!5m2!1sen!2sin"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default GoogleMap;

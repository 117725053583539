// src/components/SchemaMarkup.js
import React from 'react';
import { Helmet } from 'react-helmet';

const SchemaMarkup = () => {
    const schemaMarkupScript1 = {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Blumont Services",
          "alternateName": "Blumont Services",
          "url": "https://www.blumontservices.com/",
          "logo": "https://www.blumontservices.com/static/3cd3d1fa9ef8e748efdd9ce2669fb6ba/02c28/logo.png"
        })
      };

    const schemaMarkupScript2 = {
        type: "application/ld+json",
        innerHTML: JSON.stringify({
          "@context": "https://schema.org/",
          "@type": "WebSite",
          "name": "Blumount services",
          "url": "https://www.blumontservices.com/",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://www.blumontservices.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        })
      };
            
    return (
        <Helmet
            script={[
                schemaMarkupScript1,
                schemaMarkupScript2
            ]}
        >

        </Helmet>
    )   

};
  

export default SchemaMarkup;

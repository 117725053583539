import React from 'react'
import { rhythm } from '../utils/typography'
import { themeStyles, colors, metrics, presets } from '../utils/theme'
import {styles as buttonStyles} from '../components/Button'
import _ from 'lodash'
import GoogleMap from './GoogleMap'

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${colors.lightGrey}`,
    padding: rhythm(1),
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    maxWidth: '700px',
    minWidth: '270px',
    backgroundColor: 'white',
    [presets.Tablet]: {
      minWidth: '400px',
    },
    flex: 1,
    ...themeStyles.shadow,
  },
  input: {
    padding: rhythm(1/2),
    marginBottom: rhythm(1/2),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    flexWrap: 'wrap',
    marginTop: metrics.defaultMargin,
    marginBottom: metrics.defaultMargin,
    ...themeStyles.textPadding,
  },
  hidden: {
    display: 'none'
  },
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const ContactForm = ({ formName }) => {
  const [state, setState] = React.useState({})
  const [showDisabled, setShowDisabled] = React.useState(false)
  const [isSubmitted, setIsSubmitted] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = () => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        'subject': `New Lead ${state.first_name} ${state.last_name}`,
        ...state,
      }),
    })
      .then(() => {
        setIsSubmitted(true);
      })
      .catch((error) => console.error(error))
  }

  if (isError) {
    return (
      <div css={[themeStyles.textPadding, {backgroundColor: colors.gold, padding: metrics.defaultPadding, color: colors.white}]}>
        Sorry, there was an error. Please give us a call instead.
      </div>
    )
  }
  if (isSubmitted) {
    window.location.href = '/ThankYouPage';
  }

  const contactFields = [
    {variable: 'first_name', placeholder: 'First Name', type: 'text'},
    {variable: 'last_name', placeholder: 'Last Name', type: 'text'},
    {variable: 'phone', placeholder: 'Phone Number', type: 'phone'},
    {variable: 'email', placeholder: 'Email', type: 'email'},
    {variable: 'message', placeholder: 'How can we help?', type: 'text'},
  ]

  const careerFields = [
    {variable: 'first_name', placeholder: 'First Name', type: 'text'},
    {variable: 'last_name', placeholder: 'Last Name', type: 'text'},
    {variable: 'phone', placeholder: 'Phone Number', type: 'phone'},
    {variable: 'email', placeholder: 'Email', type: 'email'},
    {variable: 'where_do_you_live', placeholder: 'Where do you live?', type: 'text'},
    {variable: 'message', placeholder: 'Comments', type: 'text'},
  ]

  const fields = formName === 'Career' ? careerFields : contactFields

  const fieldMarkup = _.map(fields, f => {
    return (
      <React.Fragment key={f.variable}>
        <label css={styles.hidden} for={f.variable}>{f.placeholder}</label>
        <input css={[styles.input, {border: (showDisabled && (!state[f.variable] || state[f.variable] === '')) ? '1px solid red' : null }]} id={f.variable} name={f.variable} placeholder={f.placeholder} type={f.type} onChange={handleChange}/>
      </React.Fragment>
    )
  })

  const invalid = _.some(fields, f => {
    if (!state[f.variable] || state[f.variable] === '') {
      return true
    }
    return false
  })

  const onButtonClick = () => {
    if (invalid) {
      setShowDisabled(true)
    } else {
      handleSubmit()
    }
  }

  return (
    <form
      name={formName}
      method="post"
      action=""
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      css={styles.form}
      onSubmit={e => e.preventDefault()}
    >
      <label css={styles.hidden}>bot-field</label>
      <input name="bot-field" onChange={handleChange} css={{display: 'none'}} />
      {fieldMarkup}
      <div css={{marginTop: rhythm(1), marginBottom: rhythm(2)}}>
        <button css={buttonStyles.button} type='submit' onClick={onButtonClick}>
          Submit
        </button>
      </div>
      <GoogleMap></GoogleMap>
    </form>
  )
}

export default ContactForm

import React from 'react'
import { Helmet } from 'react-helmet';
import { rhythm } from '../utils/typography'
import { themeStyles, presets, colors } from '../utils/theme'
import Link from 'gatsby-link'
import Button from '../components/Button'
import withConfig from '../wrappers/withConfig'
import Img from "gatsby-image"
import Utah from '../assets/utah.png'


const styles = {
  titleContainer: {
    display: 'flex',
    flex: 1,
    height: '100%',
    alignSelf: 'stretch',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: rhythm(1/2),
    [presets.Desktop]: {
      fontSize: rhythm(5/8),
    },
    [presets.Hd]: {
      fontSize: rhythm(3/4),
    }
  },
  logo: {
    marginRight: rhythm(1 / 2),
    marginBottom: 0,
    height: rhythm(3),
  },
  topHeader: {
    backgroundColor: colors.darkGrey,
    color: colors.white,
    width: '100vw',
  },
  topHeaderInner: {
    ...themeStyles.contentWidth,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    justifyContent: 'space-between',
    padding: `${rhythm(0.25)} ${rhythm(0.25)}`,
    fontSize: rhythm(0.5),
    [presets.Tablet]: {
      fontSize: 'inherit'
    }
  },
  hideTablet: {
    display: 'none',
    [presets.Tablet]: {
      display: 'block'
    }
  }
}

const TopHeader = ({defaultDescription, phone}) => {
  return (
    <div css={styles.topHeader}>
      <Helmet>
        <meta name="google-site-verification" content="NfF88Yxg4A2K8-9t6OIGKmdtG9aeeWD2jyRpeBsqZCQ" />
        {/* Google Tag Manager script */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-MGW6HGEFNE"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-MGW6HGEFNE');
          `}
        </script>
      </Helmet>
      <div css={styles.topHeaderInner}>
        <span>
          {defaultDescription}
        </span>
        <span css={styles.hideTablet}>
          Licensed · Bonded · Insured
        </span>
        <span>
          <a
            href={`tel:${phone}`}
            css={{color: colors.white, textDecoration: 'none', fontWeight: 'inherit'}}
          >
            {phone}
          </a>
        </span>
      </div>
    </div>
  )
}

class Header extends React.Component {
  render() {
    let activeLogo = this.props.logo
    let activeStyles = styles.logo
    const isCarpet = this.props.project === 'CARPET'
    if (isCarpet) {
      activeLogo = this.props.logo_carpet
      activeStyles = {...activeStyles, padding: rhythm(0.5)}
    }
    const { defaultDescription, phone } = this.props
    return (
      <div css={{ zIndex: 100, backgroundColor: 'rgba(256,256,256,.7)', }}>
        <TopHeader
          defaultDescription={defaultDescription}
          phone={phone}
        />
        <div css={themeStyles.contentWidth}>
          <div css={[
            themeStyles.horizontalTextPadding,
            {
              display: 'flex',
              alignSelf: 'stretch',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: rhythm(1/2),
              zIndex: 100
            }
          ]}>
            <div css={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
              <Link to="/">
                <Img
                  fixed={activeLogo.childImageSharp.fixed}
                  alt={`BluMont Building Services`}
                  css={{}}
                  loading='eager'
                />
              </Link>
              <img src={Utah} css={{
                ...activeStyles,
                marginLeft: rhythm(0.5),
                display: 'none',
                [presets.Mobile]: {
                  display: 'block',
                  height: isCarpet ? rhythm(5) : null,
                },
                [presets.Tablet]: {
                  marginLeft: rhythm(1),
                  display: 'block',
                }
                }}
              />
            </div>
            <Link to='/' style={themeStyles.smallButton}>Home</Link>
            <Link to='/services' style={themeStyles.smallButton}>Services</Link>
            <Link to='/locations' style={themeStyles.smallButton}>Locations</Link>
            <Link to='/blog' style={themeStyles.smallButton}>Blogs</Link>
            <Link to='/contact' style={themeStyles.smallButton}>Contact</Link>
            <Button text='Free Quote' to='/contact'/>
          </div>
        </div>
      </div>
    )
  }
}

export default withConfig(Header)

import React from 'react'
import Link from 'gatsby-link'
import { rhythm } from '../utils/typography'
import { colors, presets } from '../utils/theme'

export const styles = {
  button: {
    borderRadius: rhythm(0.5),
    padding: `${rhythm(0.5)} ${rhythm(1)}`,
    textAlign: 'center',
    fontSize: rhythm(0.7),
    [presets.Tablet]: {
      padding: `${rhythm(0.7)} ${rhythm(1.3)}`,
      fontSize: rhythm(1),
    },
    fontFamily: 'sans-serif',
    fontWeight: '800',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    flex: -1,
    transition: 'all 0.3s ease',
    backgroundColor: colors.gold,
    ':hover': {
      backgroundColor: colors.lightGold,
    },
  },
}

const Button = ({href, to, onClick, text, style, ...otherProps}) =>  {
  const commonProps = {
    onClick: onClick,
    css: {flex: -1, display: 'flex', textDecoration: 'none', ...style},
    ...otherProps
  }

  const body = (
    <div css={[styles.button, style]}>
      <div>
        {text}
      </div>
    </div>
  )

  if (href) {
    return (
      <a {...commonProps} href={href}>
        {body}
      </a>
    )
  }
  return (
    <Link {...commonProps} to={to}>
      {body}
    </Link>
  )
}

export default Button
